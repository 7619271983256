import axios from 'axios'
//const url = 'http://localhost:8091/ivertex/'
//const url = process.env.SERVER_URI//'
//const url = 'https://api-grupoparque.intuo.app/iVertexServices/'
//const url = '/api';

const url = 'https://maestrix.grupoparque.com.br:8443/apiprevida/ivertex/'

export const http = axios.create({
     baseURL: url, 
}) 

http.defaults.timeout = 8500;

http.interceptors.response.use(function (response) {
     return response;
   }, function (error) {
     return Promise.reject(error);
   });


export default {
     url,
}